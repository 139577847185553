<template>
  <div>
    <div class="w-full my-2">
      <!-- {{ log.action_type }} -->
      <!-- {{ log.extra_data }} -->
      <div
        v-for="(ctx, ctxIndex) in context"
        :key="`ctx-${ctxIndex}`"
        class="flex items-center gap-3 py-1 text-sm"
      >
        <div class="w-2/4 md:w-1/5">
          <span class="font-medium text-gray-700" v-text="ctx.label" />
        </div>

        <div class="w-2/4 md:w-3/5">
          <component
            :is="ctx.route ? 'RouterLink' : 'div'"
            :to="ctx.route"
            target="_blank"
          >
            <XStatus
              v-if="ctx.badge"
              :variant="
                typeof ctx.badge === 'string' ? ctx.badge : ctx.badge.variant
              "
              :text="ctx.value"
              :size="typeof ctx.badge === 'string' ? 'small' : ctx.badge.size"
              :profile="
                typeof ctx.badge === 'string' ? 'trip' : ctx.badge.profile
              "
            />
            <span
              v-else
              v-text="ctx.value"
              :class="ctx.route ? 'text-blue-600' : 'text-gray-500'"
            />
          </component>
        </div>
      </div>
    </div>

    <!-- <div v-else class="my-2 text-sm text-gray-500">
      No context data available.
    </div> -->
  </div>
</template>

<script>
import XStatus from '@/components/badge/XStatus.vue'

export const contextDataTemplates = [
  'reservation',
  'trip-started',
  'unlock-req',
  'unlock-success',
  'force-completed',
  'lock-req',
  'force-locked',
  'lock-success',
  'feedback',
  'default',
]

export const positiveActions = {
  BIKE_RESERVED: 'Requested for reservation',
  BIKE_RESERVATION_CANCELLED: 'Reservation cancelled',
  BIKE_TRIP_STARTED: 'Trip started',
  BIKE_TRIP_COMPLETED: 'Trip Completed',
  BIKE_TRIP_CANCELLED: 'Force Trip Cancelled',
  BIKE_UNLOCK_REQUEST: 'Unlock Request Sent',
  BIKE_CABLE_UNLOCK_REQUEST: 'Scooter Cable unlock request sent',
  BIKE_UNLOCKED: 'Vehicle Unlocked',
  BIKE_CABLE_UNLOCKED: 'Scooter Cable unlocked',
  BIKE_LOCK_REQUEST: 'Requested for locking vehicle',
  BIKE_CABLE_LOCK_REQUEST: 'Scooter Cable lock request sent',
  BIKE_LOCKED: 'Vehicle Locked',
  BIKE_CABLE_LOCKED: 'Scooter Cable locked',
  BIKE_GOOD_FEEDBACK: 'Rider Gave ‘Good’ Feedback',
  BIKE_BAD_FEEDBACK: 'Rider Gave ‘Bad’ Feedback',
  BIKE_FORCE_LOCK_REQUEST: 'Force Lock Request',
  BIKE_FORCE_UNLOCK_REQUEST: 'Force Unlock Request',
  BIKE_FORCE_TRIP_COMPLETED: 'Force Trip Completed',
}
export const negativeActions = {
  BIKE_RESERVED: 'Requested for reservation failed',
  BIKE_RESERVATION_CANCELLED: 'Reservation cancelletion failed',
  BIKE_TRIP_STARTED: 'Trip Start Failed',
  BIKE_TRIP_COMPLETED: 'Trip Complete Failed',
  BIKE_TRIP_CANCELLED: 'Force Trip Cancel Failed',
  BIKE_UNLOCK_REQUEST: 'Unlock Request Failed',
  BIKE_CABLE_UNLOCK_REQUEST: 'Scooter Cable unlock request failed',
  BIKE_UNLOCKED: 'Vehicle failed to Unlock',
  BIKE_CABLE_UNLOCKED: 'Scooter Cable unlocking failed',
  BIKE_LOCK_REQUEST: 'Lock Request Failed',
  BIKE_CABLE_LOCK_REQUEST: 'Scooter Cable lock request failed',
  BIKE_LOCKED: 'Vehicle failed to Lock',
  BIKE_CABLE_LOCKED: 'Scooter Cable lock failed',
  BIKE_GOOD_FEEDBACK: 'Error while giving feedback',
  BIKE_BAD_FEEDBACK: 'Error while giving feedback',
  BIKE_FORCE_LOCK_REQUEST: 'Force Lock Request Failed',
  BIKE_FORCE_UNLOCK_REQUEST: 'Force Unlock Request Failed',
  BIKE_FORCE_TRIP_COMPLETED: 'Force Trip Complete Failed',
}

export default {
  components: { XStatus },
  name: 'TimelineLogLayerDetailsContext',

  props: {
    context: {
      type: null,
      required: true,
    },
  },

  methods: {},
}
</script>

<style></style>
